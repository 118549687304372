var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fun_contact_detail" }, [
    _c("div", { staticClass: "wrapper" }, [
      _vm._m(0),
      _c("div", { staticClass: "content_group" }, [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "detail_group_wrap" }, [
            _c("div", { staticClass: "detail_group" }, [
              _vm.userData
                ? _c(
                    "div",
                    { staticClass: "basic" },
                    [
                      _c("ContactDetailBasic", {
                        attrs: { userData: _vm.userData },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.coWorking && _vm.coWorking.length != 0 && 0
                ? _c(
                    "div",
                    { staticClass: "workteam" },
                    [
                      _c("ContactDetailWorkteam", {
                        attrs: { wtData: _vm.coWorking },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "growing_data_container" }, [
                _c(
                  "div",
                  {
                    staticClass: "title_group cp un_sel",
                    class: { on: _vm.switch_growing_data },
                    on: {
                      click: function ($event) {
                        _vm.switch_growing_data = !_vm.switch_growing_data
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "icon" }),
                    _c("div", { staticClass: "title" }, [
                      _vm._v("个人能力成长数据"),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.switch_growing_data,
                        expression: "switch_growing_data",
                      },
                    ],
                    staticClass: "growing_data_wrap",
                  },
                  [
                    _c("radar-growing-data", {
                      attrs: { userId: Number(_vm.userId) },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "fun_group" }, [
            _c(
              "div",
              {
                staticClass: "btn goto_dialog cp un_sel",
                on: { click: _vm.gotoDialog },
              },
              [_vm._v("进入对话")]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title_group" }, [
      _c("div", { staticClass: "title" }, [_vm._v("联系人")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }