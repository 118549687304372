var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contact_detail_workteam_unit" },
    [
      _c("WorkTeamAvatar", {
        staticClass: "avatar_wrapper",
        attrs: { userlist: _vm.unit.pictures },
      }),
      _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.unit.chatName))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }