<!-- 联系人详情 -->
<template>
  <div class="fun_contact_detail">
    <div class="wrapper">
      <div class="title_group">
        <div class="title">联系人</div>
      </div>
      <div class="content_group">
        <!-- <ContactApplyUnit class="list_unit" :info="item" v-for="(item,index) in list_group" :key="index"></ContactApplyUnit> -->
        <div class="content">
          <div class="detail_group_wrap">
            <div class="detail_group">
              <div class="basic"  v-if="userData">
                <ContactDetailBasic :userData="userData"></ContactDetailBasic>
              </div>
              <div class="workteam"  v-if="coWorking && coWorking.length != 0 && 0">
                <ContactDetailWorkteam :wtData="coWorking"></ContactDetailWorkteam>
              </div>
              <div class="growing_data_container">
                <div class="title_group cp un_sel"
                  :class="{on: switch_growing_data}"
                  @click="switch_growing_data=!switch_growing_data"
                >
                  <div class="icon"></div>
                  <div class="title">个人能力成长数据</div>
                </div>
                <div class="growing_data_wrap"
                  v-show="switch_growing_data"
                >
                  <radar-growing-data
                    :userId="Number(userId)"
                  ></radar-growing-data>
                </div>
              </div>
            </div>
          </div>
          <div class="fun_group" >
            <div class="btn goto_dialog cp un_sel" @click="gotoDialog">进入对话</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ContactDetailBasic from '@/components/Contact/ContactDetailBasic.vue';
import ContactDetailWorkteam from '@/components/Contact/ContactDetailWorkteam.vue';
import RadarGrowingData from '@/components/radar/growingData.vue';
export default {
  components:{
    ContactDetailBasic,
    ContactDetailWorkteam,
    RadarGrowingData,
  },
  data(){
    return{
      // userId: this.$route.params.id,
      userId: this.$api.moduleTask.getRouteParamsId(),
      userData: null,
      coWorking: null, //共同工作组信息
      switch_growing_data: true,
    }
  },
  watch:{
    '$route.params.id': async function(newVal){
      this.userId = this.$api.moduleTask.getRouteParamsId();
      this.getUserById();
      this.getCoWorking();
    },
    message_box_list: {
      handler: function(newval){
        if(newval == null){
        }
      },
      deep: true,
    },
  },
  computed:{
    /**消息盒子列表 */
    message_box_list(){
      return this.$store.state.message_box_list;
    },
  },
  async mounted(){
    this.getUserById();
    this.getCoWorking();
    // 如果没有消息盒子 则获取
    if(this.message_box_list == null){
      this.$store.dispatch('get_message_box_list');
    }
  },
  methods:{
    /**
     * 获取用户信息
     */
    async getUserById(){
      let userId = this.userId;
      let res = await this.$http.request('/project/getUserById',{userId});
      if(res.message=="success"){
        this.$set( this, 'userData', res.contents.user);
      }else{
        this.$toast({
          icon:'none',
          title: res.message,
        })
      }
    },
    // 获取共同工作组信息
    async getCoWorking(){
      let friendUserId = this.userId;
       let res = await this.$http.request('/projectFriends/friendChatGoupList',{friendUserId});
      if(res.message=="success"){
        this.$set( this, 'coWorking', res.contents.list);
      }else{
        this.$toast({
          icon:'none',
          title: res.message,
        })
      }
    },
    /**
     * 跳转到会话页
     */
    async gotoDialog(){
      // 查询当前消息盒子列表是否有当前用户的会话 如果没有创建
      let message_box_list = this.$tools.deepClone(this.message_box_list);
      let hasDialog = message_box_list.find((item)=>{
        return item.chatId == this.userId;
      })
      // 不存在
      if(!hasDialog){
        let message_box_list_unit = {
          masterUserid: this.$tools.getUserId(),
          chatType: 0,
          chatId: this.userId,
          messageContent: '',
          name: this.userData.friendRemark || this.userData.userName,
          photo: this.userData.thumbnail,
        }
        this.$store.commit('set_message_box_unit',message_box_list_unit );
        let not_top_index = message_box_list.findIndex( item => {
          return !item.istop;
        })
        if(not_top_index != -1){
          message_box_list.splice(not_top_index, 0, message_box_list_unit);
        }else{
          message_box_list.unshift(message_box_list_unit);
        }
        this.$store.commit('set_message_box_list', message_box_list);
      }
      this.$router.push({name:'fun_task_dialog_single', params:{id: this.$route.params.id}})
    },
  }
}
</script>
<style lang="scss" scoped>
.fun_contact_detail{
  width:100%;
  height: 100%;
  background-color: #f5f8fa;
  .wrapper{
    @include flex;
    overflow: hidden;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    .title_group{
      @include bbox;
      flex-shrink: 0;
      line-height: 64px;
      padding-left: 20px;
      width:100%;
      // position: sticky;
      // top:0;
      // background-color:#fff;
      z-index: 3;
      font-size:18px;
      
      font-weight:400;
      color:rgba(51,51,51,1);
    }
    .content_group{
      @include bbox;
      @include flex;
      overflow: hidden;
      flex: 1;
      justify-content: center;
      width:100%;
      height: 100%;
      .content{
        @include flexsb;
        @include scrollbar;
        @include bbox;
        padding: 60px 0 50px;
        // width: 360px;
        width: 100%;
        flex-direction: column;
        height: 100%;
        .detail_group_wrap{
          width: 100%;
          height: 100%;
          flex: 1;
          overflow-y: auto;
        }
        .detail_group{
          // @include scrollbar;
          @include hidescrollbar;
          @include flex;
          // overflow-y: auto;
          width: 360px;
          flex-direction: column;
          align-items: stretch;
          height: 100%;
          flex: 1;
          margin: 0 auto;
          .basic{
            background-color:#f5f8fa;
            flex-shrink: 0;
          }
          .workteam{
            @include bbox;
            @include scrollbar;
            padding-top: 29px;
            overflow-y: auto;
            flex:1;
            height:100%;
          }

        }
        .fun_group{
          flex-shrink: 0;
          padding-top: 65px;
          .btn{
            padding:0 65px;
            background:$main_blue;
            border-radius:4px;
            line-height: 50px;
            font-size:18px;
            
            font-weight:400;
            color:rgba(255,255,255,1);
          }
        }
      }
    }
  }
  .content{
    .growing_data_container{
      background-color: transparent;
      .title_group{
        @include flex;
        padding-bottom: 8px;
        padding-left: 0;
        &.on{
          .icon{
            background-size: 10px 8px;
            background-image: url(~@/assets/images/personal_center/open.png);
          }
        }
        .icon{
          @include background(8px, 10px);
          // @include transition;
          width: 20px;
          height: 20px;
          background-image: url(~@/assets/images/personal_center/close.png);
        }
        .title{
          font-size:15px;
          font-weight:400;
          color:rgba(51,51,51,1);
        }
      }
      .growing_data_wrap{
        position: relative;
        width: 100%;
        height: 500px;
      }
    }
  }
}
</style>