<!--
 * @Author       : Hugo
 * @Date         : 2020-05-27 10:41:17
 * @LastEditors  : Hugo
 * @LastEditTime : 2020-09-22 11:57:16
 * @Description  : 工作组详情(容器)
 * @FilePath     : \miaohang\src\components\Contact\ContactDetailWorkteam.vue
--> 
<template>
  <div class="contact_detail_workteam">
    <div class="title_group" :class="{on: status_workteam}"  v-show="status_workteam">
      <div class="icon cp" @click.stop="status_workteam=!status_workteam"></div>
      <div class="title cp" @click.stop="status_workteam=!status_workteam">共事工作组</div>
    </div>
    <ContactDetailWorkteamUnit v-show="status_workteam" class="unit" v-for="(item,index) in wtData" :unit="item" :key="index"></ContactDetailWorkteamUnit>
    <div class="line"></div>
  </div>
</template>
<script>
import ContactDetailWorkteamUnit from '@/components/Contact/ContactDetailWorkteamUnit.vue';
export default {
  components:{
    ContactDetailWorkteamUnit,
  },
  props:{
    wtData: {
      type: Array,
      default: null,
    }
  },
  data(){
    return{
      status_workteam: false,
    }
  },
  watch:{
    '$route':function(){
      this.status_workteam = false;
    }
  },
}
</script>
<style lang="scss" scoped>
.contact_detail_workteam{
  @include scrollbar;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color:#f5f8fa;
  .title_group{
    @include flex;
    @include u_sel_none;
    position: sticky;
    top:0;
    background-color:#f5f8fa;
    z-index: 3;
    padding-bottom: 29px;
    &.on{
      .icon{
        width: 10px;
        height: 8px;
        background-image: url(~@/assets/images/common/workteam_extend.png);
      }
    }
    .icon{
      @include transition;
      width: 8px;
      height: 10px;
      background-size: 100%;
      background-image: url(~@/assets/images/common/workteam_packup.png);
      cursor: pointer;
    }
    .title{
      padding-left:10px;
      font-size:15px;
      
      font-weight:400;
      color:rgba(51,51,51,1);
    }
  }
  .unit{
    width: 100%;
    margin-bottom: 20px;
  }
  .line{
    width:100%;
    height:2px;
    background-color: $border_color;
    position: sticky;
    bottom:0;
  }
}
</style>
