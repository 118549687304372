<!--
 * @Author       : Hugo
 * @Date         : 2020-05-27 10:41:32
 * @LastEditors  : Hugo
 * @LastEditTime : 2020-06-05 15:21:20
 * @Description  : 工作组详情(单项)
 * @FilePath     : \miaohang\src\components\contact\contactDetailWorkteamUnit.vue
--> 
<template>
  <div class="contact_detail_workteam_unit">
    <WorkTeamAvatar class="avatar_wrapper" :userlist="unit.pictures"></WorkTeamAvatar>
    <div class="name">{{unit.chatName}}</div>
  </div>
</template>
<script>
import WorkTeamAvatar from '@/components/basic/workTeamAvatar.vue';
export default {
  components:{
    WorkTeamAvatar,
  },
  props:{
    unit: {
      type: Object,
      default: null,
    }
  }
  
}
</script>

<style lang="scss" scoped>
.contact_detail_workteam_unit{
  @include flex;
  width:100%;
  background-color:#f5f8fa;
  .avatar_wrapper{
    flex-shrink: 0;
    // display: block;
    width: 44px;
    height: 44px;
  }
  .name{
    @include bbox;
    padding-left:15px;
    flex:1;
    width: 100%;
    font-size:14px;
    
    font-weight:400;
    color:rgba(102,102,102,1);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

}
</style>