var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contact_detail_workteam" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.status_workteam,
              expression: "status_workteam",
            },
          ],
          staticClass: "title_group",
          class: { on: _vm.status_workteam },
        },
        [
          _c("div", {
            staticClass: "icon cp",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.status_workteam = !_vm.status_workteam
              },
            },
          }),
          _c(
            "div",
            {
              staticClass: "title cp",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.status_workteam = !_vm.status_workteam
                },
              },
            },
            [_vm._v("共事工作组")]
          ),
        ]
      ),
      _vm._l(_vm.wtData, function (item, index) {
        return _c("ContactDetailWorkteamUnit", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.status_workteam,
              expression: "status_workteam",
            },
          ],
          key: index,
          staticClass: "unit",
          attrs: { unit: item },
        })
      }),
      _c("div", { staticClass: "line" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }